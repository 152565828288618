import React from 'react'
import { Layout } from 'organisms'
import { ContactSection } from 'molecules'

const ContactPage = () => (
	<Layout>
		<ContactSection email="louis.cospain@protonmail.com" />
	</Layout>
)

export default ContactPage
